import { useEffect, useRef, useState } from 'react';
import tw from 'tailwind-styled-components';
import { lighten, darken, desaturate, getLuminance } from 'polished';
import NextImage from 'next/image';
import clsx from 'clsx';
import { ArrowRight } from '@styled-icons/fa-solid/ArrowRight';

import Button from '@/components/button';

const Container = tw.div`
  relative
  rounded-2xl
  min-h-[18rem]
  transition-all
  duration-1000
  delay-150
  p-8

  md:flex
  md:items-center

  lg:px-16
  lg:py-14
`;

const Content = tw.div`
  relative
  z-10

  md:w-2/3

  lg:w-1/2
`;

const Title = tw.h1`
  font-inter
  font-black
  text-5xl
  mb-6

  lg:mb-12
  lg:text-7xl
`;

const Subtitle = tw.h2`
  text-4xl
  font-thin
`;

const Background = tw.div`
  absolute
  rounded-2xl
  transition-transform
  will-change-transform
  -z-10
`;

const ImageContainer = tw.div`
  pointer-events-none

  md:overflow-hidden
  md:w-full
  md:absolute
  md:left-0
  md:-top-8
  md:bottom-0
  md:rounded-2xl

  lg:-top-16
`;

const ImageWrapper = tw.div`
  overflow:hidden

  md:absolute
  md:top-0
  md:w-[45%]
`;

const Image = tw(NextImage)`
  object-contain
`;

// const MASK_CLOSED = 'polygon(0 -64px, 100% -64px, 100% calc(100% + 64px), 0 calc(100% + 64px))';
// const MASK_OPEN = 'polygon(0 -64px, 110% -64px, 110% calc(150% + 64px), 0 calc(150% + 64px))';

const SectionHeader = ({ colors, href, imageUrl, imagePosition = 'auto', isVisible, subtitle, title }) => {
  const [rect, setRect] = useState({ top: 0, left: 0, height: 0, width: 0 });
  const containerRef = useRef(null);
  const luminance = getLuminance(colors.background);

  // console.log(`${luminance} %c${'  '}`, `color: ${colors.background}; background-color: ${colors.background}`);

  const lightFactor = luminance >= 0.85 ? 0.2 : 0.1;
  const darkFactor = luminance >= 0.85 ? 0.085 : 0.05;
  const desaturationFactor = luminance >= 0.85 ? 0.25 : 0.1;

  const lightShadow = lighten(lightFactor / luminance, colors.background);
  const darkShadow = desaturate(desaturationFactor, darken(darkFactor / luminance, colors.background));

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();

        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

        setRect({
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft,
            width: rect.width,
            height: rect.height
        });
      }
    };

    const timeout = setTimeout(handleResize, 250);

    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeout);
    }
  }, [containerRef]);

  return (
    <>
      <Background
        style={{
          width: rect.width,
          left: rect.left,
          top: rect.top,
          height: rect.height,
          backgroundColor: colors.background,
          transitionDuration: isVisible ? '2s' : '1s',
          transform: `translate3d(0, 0, 0) scale(${isVisible ? 10 : 1})`
        }}
      />
      <Container
        ref={containerRef}
        style={{
          backgroundColor: colors.background,
          boxShadow: isVisible ? `20px 20px 60px ${darkShadow}, -20px -20px 60px ${lightShadow}` : 'none',
        }}
      >
        {imageUrl &&
          <ImageContainer>
            <ImageWrapper className={
              clsx({
                'md:-right-16 md:-bottom-24': imagePosition === 'auto',
                'md:right-0 md:bottom-0': imagePosition === 'right'
              })
            }>
              <Image
                alt=""
                fill
                size="50%"
                src={imageUrl}
              />
            </ImageWrapper>
          </ImageContainer>
        }
        <Content>
          {title && <Title style={{ color: colors.title }}>{title}</Title>}
          {subtitle && <Subtitle style={{ color: colors.subtitle }}>{subtitle}</Subtitle>}
          {typeof href === 'string' && href.trim().length > 0 &&
            <Button className="mt-8" size="large" to={href} variant="ghost">Learn More <ArrowRight className="-mt-[2px] ml-1" size={14} /></Button>
          }
        </Content>
      </Container>
    </>
  );
};

export default SectionHeader;
