import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import tw from 'tailwind-styled-components';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';

const Video = tw.video``;

/**
 * @typedef {{ src: string, type?: string }} VideoSource
 * @typedef {number|'hover'|'click'|'intersection'} ShowAfter
 * @param {object} props
 * @param {ShowAfter?} props.showAfter - Show the video after a delay, on hover, or on click
 * @param {string} props.poster - The poster image to show before the video loads
 * @param {VideoSource[]} props.sources - The video sources
 * @param {{ height: number, width: number }} props.size - The size of the placeholder image
 * @param {string} props.className - The class name to apply to the video
 */
function Player ({ className, showAfter = 1000, poster, sources = [], size = { height: 0, width: 0 } }, ref) {
  const videoRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [inViewRef, inView] = useInView({ threshold: 0.5 });

  const handleHover = () => {
    if (showAfter === 'hover') {
      setShowVideo(true);
    }
  };

  const handleClick = () => {
    if (showAfter === 'click') {
      setShowVideo(true);
    }
  };

  useImperativeHandle(ref, () => videoRef.current);

  useEffect(() => {
    let timer;

    if (typeof showAfter === 'number') {
      timer = setTimeout(() => {
        setShowVideo(true);
      }, showAfter);
    }

    return () => clearTimeout(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inView && showAfter === 'intersection') {
      setShowVideo(true);
    }
  }, [inView, showAfter]);

  return (
    <>
      {!showVideo && poster && (
        <Image
          ref={inViewRef}
          className={className}
          src={poster}
          alt="Video Poster"
          onClick={handleClick}
          onMouseEnter={handleHover}
          {...size}
        />
      )}
      {showVideo && (
        <Video className={className} ref={videoRef} autoPlay playsInline loop muted>
          {sources.map((video, index) => (
            <source key={index} src={video.src} type={video.type || 'video/mp4'} />
          ))}
        </Video>
      )}
    </>
  );
}

Player.displayName = 'Video';

export default memo(forwardRef(Player));
