import React, { useState, useRef } from 'react';
import tw from 'tailwind-styled-components';
import { darken, desaturate, getLuminance, transparentize } from 'polished';
import Link from '@/components/link';
import { ArrowRight } from '@styled-icons/fa-solid/ArrowRight';
import NextImage from 'next/image';

const Perspective = tw(Link)`
  [perspective:500px]
  [transform-style:preserve-3d]
  cursor-pointer
  hover:no-underline
`;

const Container = tw.div`
  relative
  rounded-2xl
  flex
  flex-col
  overflow-hidden
  h-full
  transform-gpu
  origin-center

  lg:aspect-square
  lg:h-auto
`;

const Reflection = tw.div`
  absolute
  top-0
  left-0
  w-full
  h-full
  pointer-events-none
  transition-all
  duration-500
  mix-blend-overlay
  z-10
`;

const Content = tw.div`
  mt-auto
  p-8
  relative
  z-10

  lg:p-16
`;

const BgTitle = tw.h1`
  absolute
  whitespace-nowrap
  font-inter
  font-black
  text-[18rem]
  mix-blend-overlay
  leading-none
  -top-16
  -left-8
  text-black
  opacity-20
`;

const Title = tw.h1`
  text-5xl
  font-inter
  font-black
  mb-8

  lg:text-6xl
`;

const Description = tw.h2`
  text-xl
  font-normal

  lg:text-2xl
`;

const ImageWrapper = tw.div`
  relative
  w-1/2
  aspect-square
  mx-auto
  top-12

  lg:mt-0
  lg:mx-0
  lg:right-0
  lg:bottom-1/2
  lg:translate-x-2
  lg:absolute
`;

const Image = tw(NextImage)`
  object-contain
`;

const ReadMore = tw.span`
  font-medium
  block
  text-lg
  text-right
  mt-8
  -mb-4
`;

const damping = (value) => {
  const factor = 0.5;
  return value / (1 + Math.abs(value) * factor);
};

const Feature = ({ colors, description, imageUrl, title, href = '' }) => {
  const pendingRef = useRef(false);
  const eventRef = useRef(null);
  const containerRef = useRef(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [textShadow, setTextShadow] = useState({ x: 0, y: 0 });
  const [gradient, setGradient] = useState('none');
  const [hasTransition, setHasTransition] = useState(false);
  const [reflectionOpacity, setReflectionOpacity] = useState(0);
  const tiltMagnitude = Math.sqrt(rotation.x * rotation.x + rotation.y * rotation.y);
  const shadowOffset = { x: -rotation.y * 25, y: rotation.x * 25 };
  const shadowBlur = Math.min(2 + 2 * tiltMagnitude, 20);
  const luminance = getLuminance(colors.containerBg);
  const transparentizeFactor = luminance >= 0.9 ? 0.8 : 0.5;
  const shadowColor = transparentize(transparentizeFactor, desaturate(0.5, darken(0.25, colors.containerBg)));

  const updateFrame = () => {
    if (!eventRef.current || !containerRef.current) return;

    const e = eventRef.current;

    const containerRect = containerRef.current.getBoundingClientRect();
    const offsetX = damping((e.clientX - containerRect.left) / containerRect.width - 0.5);
    const offsetY = damping((e.clientY - containerRect.top) / containerRect.height - 0.5);
    const relX = (e.clientX - containerRect.left) / containerRect.width - 0.5;
    const relY = (e.clientY - containerRect.top) / containerRect.height - 0.5;
    const rotationX = relY * (1 - Math.abs(relY)) * 5;
    const rotationY = -relX * (1 - Math.abs(relX)) * 5;
    const dy = e.clientY - (containerRect.top + containerRect.height / 2);
    const dx = e.clientX - (containerRect.left + containerRect.width / 2);
    const theta = Math.atan2(dy, dx);
    let angle = theta * 180 / Math.PI - 90;

    if (angle < 0) {
      angle += 360;
    }

    setRotation({ x: rotationX, y: rotationY });
    setOffset({ x: -offsetX * 15, y: -offsetY * 15 });
    setTextShadow({ x: -offsetX * 5, y: offsetY * 5 });
    setGradient(`linear-gradient(${angle}deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 80%)`);

    pendingRef.current = false;
  };

  const handleMouseMove = (e) => {
    if (!containerRef.current) return;

    eventRef.current = e; // Store the current event

    if (!pendingRef.current) {
      pendingRef.current = true;
      requestAnimationFrame(updateFrame);
    }
  };

  const handleMouseEnter = () => {
    setHasTransition(false);
    setReflectionOpacity(1);
  };

  const handleMouseLeave = () => {
    setHasTransition(true);
    setReflectionOpacity(0);

    setTimeout(() => {
      setOffset({ x: 0, y: 0 });
      setRotation({ x: 0, y: 0 });
      setTextShadow({ x: 0, y: 0 });
    }, 0);
  };

  return (
    <Perspective href={href}>
      <Container
        ref={containerRef}
        style={{
          backgroundColor: colors.background,
          transition: hasTransition ? 'all 250ms ease-out' : 'none',
          transform: `rotate3d(${rotation.x}, ${rotation.y}, 0, 2deg)`,
          boxShadow: `${shadowOffset.x / 5}px ${shadowOffset.y / 5}px ${shadowBlur * 4}px ${shadowColor}, ${shadowOffset.x}px ${shadowOffset.y}px ${shadowBlur * 10}px ${shadowColor}`
        }}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {title &&
          <BgTitle>
            {title}
          </BgTitle>
        }
        {imageUrl &&
          <ImageWrapper>
            <Image
              alt=""
              fill
              sizes="50%"
              src={imageUrl}
              style={{ transform: `translate3d(${offset.x / 2}px, ${offset.y / 2}px, 0) rotate3d(${rotation.x}, ${rotation.y}, 0, 2deg)` }}
            />
          </ImageWrapper>
        }
        <Content
          style={{
            transform: `translate3d(${offset.x / 1.5}px, ${offset.y / 1.5}px, 0) rotate3d(${rotation.x}, ${rotation.y}, 0, 2deg)`,
            transition: hasTransition ? 'transform 250ms ease-out' : 'none',
            textShadow: `${textShadow.x}px ${textShadow.y}px 5px rgba(0, 0, 0, 0.25)`
          }}
        >
          {title && <Title style={{ color: colors.title }}>{title}</Title>}
          {description &&
            <Description style={{ color: colors.title }}>
              {description}
              {typeof href === 'string' && href.trim().length > 0 &&
                <ReadMore>Learn More <ArrowRight className="stroke-2" size={16} /></ReadMore>
              }
            </Description>
          }
        </Content>
        <Reflection
          style={{
            backgroundImage: gradient,
            opacity: reflectionOpacity
          }}
        />
      </Container>
    </Perspective>
  );
};

export default Feature;
