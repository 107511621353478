import { forwardRef, memo } from 'react';
import tw from 'tailwind-styled-components';
import { Grid, Column } from '@bidalihq/ui';

import MaxWidth from '@/components/max-width';
import SectionHeader from '@/components/home/section-header';
import Feature from '@/components/home/feature';

const Container = tw.div`
  mb-32
`;

const Spend = forwardRef(({ isVisible, ...props }, ref) => {
  return (
    <Container {...props} ref={ref}>
      <MaxWidth className="px-4 xl:px-0">
        <Grid className="gap-8" columns={[1, 2]}>
          <Column span={[1, 2]}>
            <SectionHeader
              colors={{
                background: '#CECFFF',
                title: '#E35180',
                subtitle: '#0C0B3E'
              }}
              href="https://giftcards.bidali.com"
              imageUrl="/images/laptop.webp"
              imagePosition="right"
              isVisible={isVisible}
              title="Gift Cards Store"
              subtitle="From here to anywhere. Gift cards that close the distance."
            />
          </Column>
          <Column>
            <Feature
              colors={{ background: '#4B4DF1', title: '#FFFFFF', containerBg: '#CECFFF' }}
              description="Embed Bidali's store and let your users seamlessly convert digital assets into global shopping."
              href="https://giftcards.bidali.com"
              imageUrl="/images/puzzle.webp"
              title="Integrate"
            />
          </Column>
        </Grid>
      </MaxWidth>
    </Container>
  );
});

Spend.displayName = 'Spend';

export default memo(Spend);
