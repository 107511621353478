import { forwardRef, memo } from 'react';
import tw from 'tailwind-styled-components';
import { Grid, Column } from '@bidalihq/ui';

import MaxWidth from '@/components/max-width';
import SectionHeader from '@/components/home/section-header';
import Feature from '@/components/home/feature';

const Container = tw.div`
  mb-32
`;

const Rewards = forwardRef(({ isVisible, ...props }, ref) => {
  return (
    <Container {...props} ref={ref}>
      <MaxWidth className="px-4">
        <Grid className="gap-8" columns={[1, 2]}>
          <Column span={[1, 2]}>
            <SectionHeader
              colors={{
                background: '#DBFECE',
                title: '#547B45',
                subtitle: '#4d9531'
              }}
              href="/business/rewards"
              imageUrl="/images/trophy.webp"
              imagePosition="right"
              isVisible={isVisible}
              title="Rewards & Incentives"
              subtitle="Increase sales, brand exposure, and customer loyalty."
            />
          </Column>
          <Column>
            <Feature
              colors={{ background: '#547B45', title: '#FFFFFF', containerBg: '#DBFECE' }}
              description="Bolster team retention by offering transparent, instant recognition for employee achievements and efforts."
              href="/business/rewards"
              imageUrl="/images/heart.webp"
              title="Loyalty"
            />
          </Column>
          <Column>
            <Feature
              colors={{ background: '#4d9531', title: '#FFFFFF', containerBg: '#DBFECE' }}
              description="Sharpen marketing impact, significantly curtail excessive advertising outlay, and optimize promotional expenditures."
              href="/business/rewards"
              imageUrl="/images/megaphone.webp"
              title="Impact"
            />
          </Column>
        </Grid>
      </MaxWidth>
    </Container>
  );
});

Rewards.displayName = 'App';

export default memo(Rewards);
