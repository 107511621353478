import { forwardRef, memo } from 'react';
import tw from 'tailwind-styled-components';
import { Grid, Column } from '@bidalihq/ui';

import MaxWidth from '@/components/max-width';
import SectionHeader from '@/components/home/section-header';
import Feature from '@/components/home/feature';

const Container = tw.div`
  mb-32
`;

const App = forwardRef(({ isVisible, ...props }, ref) => {
  return (
    <Container {...props} ref={ref}>
      <MaxWidth className="px-4">
        <Grid className="gap-8" columns={[1, 2]}>
          <Column span={[1, 2]}>
            <SectionHeader
              colors={{
                background: '#FCE7EE',
                title: '#4B4DF1',
                subtitle: '#5E1A30'
              }}
              href="/app"
              imageUrl="/images/phone.webp"
              isVisible={isVisible}
              title="Money as it should be"
              subtitle="One simple balance that lets you pay, save, and earn."
            />
          </Column>
          <Column>
            <Feature
              colors={{ background: '#E35180', title: '#FFFFFF', containerBg: '#FCE7EE' }}
              description="We stand by transparency. No hidden fees, no surprises – just straightforward transactions every time."
              href="/app"
              imageUrl="/images/piggy-bank.webp"
              title="Cheap"
            />
          </Column>
          <Column>
            <Feature
              colors={{ background: '#9351E3', title: '#FFFFFF', containerBg: '#FCE7EE' }}
              description="Experience instant transfers with us. No more waiting — your funds are available immediately."
              href="/app"
              imageUrl="/images/stopwatch.webp"
              title="Fast"
            />
          </Column>
        </Grid>
      </MaxWidth>
    </Container>
  );
});

App.displayName = 'App';

export default memo(App);
