import { forwardRef } from 'react';
import tw from 'tailwind-styled-components';
import { Grid, Column } from '@bidalihq/ui';
import { TypeAnimation } from 'react-type-animation';
import { animated, useScroll, to } from 'react-spring';

import MaxWidth from '@/components/max-width';
import Button from '@/components/button';
import Video from '@/components/video';

const Container = tw.div`
  text-center
  flex
  flex-col
  items-center
  justify-center
  min-h-[calc(100vh-5rem)]
  mb-16
  relative
  z-10

  md:px-12
  xl:px-0
`;

const Title = tw.h1`
  text-5xl
  text-[#0C0941]
  text-center
  font-inter
  font-black
  -mt-[10vh]
  mb-12

  md:text-7xl
  lg:text-8xl
`;

const Emphasis = tw.em`
  text-brand-primary
  [font-style:normal]
`;

const Subtitle = tw.h3`
  text-xl
  text-[#0C0941]
  font-medium
  text-center
  mb-12

  md:text-2xl
`;

const Coins = tw(animated.div)`
  absolute
  top-0
  left-0
  right-0
  bottom-0
  will-change-transform
`;

const CoinBase = tw.div`
  absolute
  aspect-square
  h-20
  w-auto

  md:h-40
`;

const CoinTopLeft = tw(CoinBase)`
  left-[14vw]
  top-0
  h-24

  md:h-44
`;

const CoinTopRight = tw(CoinBase)`
  right-[10vw]
  top-[7vh]
`;

const CoinBottomRight = tw(CoinBase)`
  bottom-[10vh]
  right-[10vw]
`;

const CoinBottomLeft = tw(CoinBase)`
  bottom-[10vh]
  left-[11vw]
  h-28

  md:h-48
`;

const BackgroundCoin = tw.img`
  absolute
  opacity-50
  hidden

  md:block
  md:scale-50
  md:opacity-75
`;

const Hero = forwardRef(({ isVisible, ...props }, ref) => {
  const { scrollY } = useScroll();

  const sequence = [
    'money',
    2000,
    'commerce',
    2000,
    'finance',
    2000
  ];

  return (
    <>
      <Container {...props} ref={ref}>
        <BackgroundCoin className="top-[28vh] left-[2vw]" src="/images/nike.png" alt="" />
        <BackgroundCoin className="-top-[10vh] left-[30vw] scale-[0.3] blur-lg" src="/images/CAD.png" alt="" />
        <BackgroundCoin className="top-[24vh] right-[0]" src="/images/USD.png" alt="" />
        <Coins style={{ transform: to(scrollY, y => `translate3d(0, -${y * 0.25}px, 0)`) }}>
          <CoinTopRight>
            <Video
              showAfter={8000}
              poster="/images/usdc-nike-placeholder.webp"
              size={{ height: 256, width: 256 }}
              sources={[{ src: '/videos/usdc-nike.webm', type: 'video/webm' }]}
            />
          </CoinTopRight>

          <CoinBottomRight>
            <Video
              showAfter={8000}
              poster="/images/apple-usd-placeholder.webp"
              size={{ height: 256, width: 256 }}
              sources={[{ src: '/videos/usd-apple.webm', type: 'video/webm' }]}
            />
          </CoinBottomRight>

          <CoinTopLeft>
            <Video
              showAfter={8000}
              poster="/images/eur-usd-placeholder.webp"
              size={{ height: 256, width: 256 }}
              sources={[{ src: '/videos/eur-usd.webm', type: 'video/webm' }]}
            />
          </CoinTopLeft>

          <CoinBottomLeft>
            <Video
              showAfter={8000}
              poster="/images/cad-amazon-placeholder.webp"
              size={{ height: 256, width: 256 }}
              sources={[{ src: '/videos/cad-amazon.webm', type: 'video/webm' }]}
            />
          </CoinBottomLeft>
        </Coins>
        <MaxWidth className="px-4 relative z-10">
          <Grid columns={[1, 1, 6]}>
            <Column start={[1, 1, 2]} span={[1, 1, 4]}>
              <Title>
                Infrastructure for the future of <br /><Emphasis><TypeAnimation preRenderFirstString sequence={sequence} cursor={true} repeat={Infinity} /></Emphasis>
              </Title>
              <Subtitle>Bidali saves you money and helps businesses increase sales, reduce fees, and eliminate fraud with next generation payments and gift cards.</Subtitle>
              <Button size="large" to="https://webforms.pipedrive.com/f/2XDkDOjqONUu7rx7Qk5I54WHpBDOy5cvlydcajemhzrBLh1GNpu5rvXC2IQr05pvl">Contact Sales</Button>
            </Column>
          </Grid>
        </MaxWidth>
      </Container>
    </>
  );
});

Hero.displayName = 'Hero';

export default Hero;
