import { forwardRef, memo } from 'react';
import tw from 'tailwind-styled-components';
import { Grid, Column } from '@bidalihq/ui';

import MaxWidth from '@/components/max-width';
import SectionHeader from '@/components/home/section-header';
import Feature from '@/components/home/feature';

const Container = tw.div`
  mb-32
`;

const BrandedCurrency = forwardRef(({ isVisible, ...props }, ref) => {
  return (
    <Container {...props} ref={ref}>
      <MaxWidth className="px-4">
        <Grid className="gap-8" columns={[1, 2]}>
          <Column span={[1, 2]}>
            <SectionHeader
              colors={{
                background: '#FAF9D7',
                title: '#815A17',
                subtitle: '#815A17'
              }}
              href="/business/branded-currency"
              imageUrl="/images/starbucks.webp"
              isVisible={isVisible}
              title="Branded Currency"
              subtitle="Next-gen gift cards"
            />
          </Column>
          <Column>
            <Feature
              colors={{ background: '#F4F389', title: '#3B1D0C', containerBg: '#FAF9D7' }}
              description="With cryptographically secure gift cards, every transaction is traceable, ensuring transparency and dramatically mitigating fraud risks."
              href="/business/branded-currency"
              imageUrl="/images/safe-alt.webp"
              title="Secure"
            />
          </Column>
          <Column>
            <Feature
              colors={{ background: '#D5F489', title: '#24310A', containerBg: '#FAF9D7' }}
              description="Seamlessly cater to clients across borders, expanding your reach and ensuring consistent usability, regardless of location."
              href="/business/branded-currency"
              imageUrl="/images/globe.webp"
              title="Global"
            />
          </Column>
        </Grid>
      </MaxWidth>
    </Container>
  );
});

BrandedCurrency.displayName = 'BrandedCurrency';

export default memo(BrandedCurrency);
