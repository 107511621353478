import { useCallback, useEffect, useRef, useState } from 'react';
import { withTracker } from '@/tracker';
import tw from 'tailwind-styled-components';

import Hero from '@/components/home/hero';
import BrandedCurrency from '@/components/home/branded-currency';
import App from '@/components/home/app';
import Spend from '@/components/home/spend';
import Rewards from '@/components/home/rewards';

const Container = tw.div`
  overflow-hidden
`;

function Home() {
  const [dominantRef, setDominantRef] = useState(null);
  const heroRef = useRef(null);
  const brandedCurrencyRef = useRef(null);
  const appRef = useRef(null);
  const spendRef = useRef(null);
  const rewardsRef = useRef(null);
  const rafId = useRef(null);
  const lastExecution = useRef(0);
  const throttleDuration = 500;

  const isDominant = useCallback(
    (name) => dominantRef?.name === name,
    [dominantRef]
  );

  const getDominantRef = () => {
    const now = Date.now();

    if (now - lastExecution.current >= throttleDuration) {
      const refs = [
        { name: 'hero', color: 'transparent', node: heroRef },
        { name: 'branded-currency', color: '#FAF9D7', node: brandedCurrencyRef },
        { name: 'app', color: '#FCE7EE', node: appRef },
        { name: 'spend', color: '#CECFFF', node: spendRef },
        { name: 'rewards', color: 'rgb(219, 255, 206)', node: rewardsRef },
      ];

      let maxVerticalOverlap = 0;
      let currentDominant = null;

      const viewportHeight = window.innerHeight;

      refs.forEach(ref => {
        const { node } = ref;
        if (!node.current) return;

        const rect = node.current.getBoundingClientRect();
        const topOverlap = Math.max(0, viewportHeight - rect.top);
        const bottomOverlap = Math.max(0, rect.bottom);
        const verticalOverlap = Math.min(topOverlap, bottomOverlap, rect.height);

        if (verticalOverlap > maxVerticalOverlap) {
          maxVerticalOverlap = verticalOverlap;
          currentDominant = ref;
        }
      });

      setDominantRef(currentDominant);
      lastExecution.current = now;
    }

    rafId.current = requestAnimationFrame(getDominantRef);
  };

  useEffect(() => {
    rafId.current = requestAnimationFrame(getDominantRef);

    return () => {
      if (rafId.current) {
        cancelAnimationFrame(rafId.current);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Hero ref={heroRef} isVisible={isDominant('hero')} />
      <BrandedCurrency ref={brandedCurrencyRef} isVisible={isDominant('branded-currency')} />
      <App ref={appRef} isVisible={isDominant('app')} />
      <Spend ref={spendRef} isVisible={isDominant('spend')} />
      <Rewards ref={rewardsRef} isVisible={isDominant('rewards')} />
    </Container>
  );
}

export default withTracker(Home);

export async function getStaticProps() {
  return { props: { htmlClassName: 'home' } };
}
